<template>
  <b-collapse v-model="show">
    <b-form class="d-flex justify-content-end" @submit="onSubmit">
      <b-form-group
          id="email"
          class="mt-2"
          label-for="email-input"
          description="Email address for new user"
      >
        <b-form-input
            id="email-input"
            v-model="email"
            type="email"
            placeholder="Enter email"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="name"
          class="mt-2"
          label-for="name-input"
          description="Users Name"
      >
        <b-form-input
          id="name-input"
          v-model="name"
          placeholder="User Name"
          required
        />
      </b-form-group>
      <b-btn
        variant="success"
        type="submit"
        class="h-50 mt-2 ml-3"
      >
        Create
      </b-btn>
    </b-form>
  </b-collapse>
</template>

<script>
import {httpsCallable, getFunctions} from "firebase/functions";

export default {
  name: "NewUser",
  props: ['show'],
  data(){
    return {
      email: '',
      name: ''
    }
  },
  methods: {
    onSubmit(e){
      e.preventDefault();
      let password = Math.random().toString(36).slice(-8);
      httpsCallable(
          getFunctions(),
          "addUser"
      )({
        email: this.email,
        name: this.name,
        password
      }).then((res) => {
        this.$notify({
          group: 'main-notifications',
          type: 'success',
          title: `User created with password ${password}`
        })
        this.email = password;
      })
    }
  }

}
</script>

<style scoped>

</style>