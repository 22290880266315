<template>
  <div class="d-flex justify-content-between mb-2">
    <div class="ml-3">
      {{ user.email }}
    </div>
    <div>
      <b-btn @click="approve" variant="success">
        <span v-if="approveLoading"><b-spinner/></span>
        <span v-else>
          Approve
        </span>
      </b-btn>
      <b-btn @click="deny" variant="danger" class="ml-2">
        Deny
      </b-btn>
    </div>
  </div>
</template>

<script>
import {httpsCallable, getFunctions} from "firebase/functions";
import {updateDoc, doc, getFirestore} from 'firebase/firestore';
export default {
  name: "EmployeeRequest",
  props: ['user'],
  data(){
    return {
      approveLoading: false,
      denyLoading: false,
    }
  },
  methods: {
    approve() {
      this.approveLoading = false;
      httpsCallable(
          getFunctions(),
          "setEmployeeRole"
      )({email: this.user.email, id: this.user.id}).then((result) => {
        this.approveLoading = false;
        this.$emit("userUpdate");
        this.$notify({
          group: 'main-notifications',
          type: 'success',
          title: 'Approved',
          text: 'User registered as employee'
        })
        updateDoc(doc(getFirestore(), `users/${this.user.id}`), {
          employeeRequested: false
        })
      })
    },

    deny() {
      updateDoc(doc(getFirestore(), `users/${this.user.id}`), {
        employeeRequested: false
      }).then(() => {
        this.$notify({
          group: 'main-notifications',
          type: 'success',
          title: 'Rejected',
          text: 'User employee request rejected'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>