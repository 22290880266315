<template>
  <b-container class="pb-5">
    <PageTitle title="Users"/>

    <div v-if="employeeRequests.length > 0">
      <PageTitle subtitle="Users Requesting Employee Access"/>
      <EmployeeRequest
          v-for="user in employeeRequests"
          :user="user"
          :key="`emp-request-${user.uid}`"
      />
    </div>
    <div class="d-flex">
      <div class="my-auto ml-auto mr-2">
        <b-form-input
          v-model="emailSearch"
          placeholder="Email Search"
        ></b-form-input>
      </div>
      <b-btn
          @click="employees = !employees"
          class="potomac-btn"
          :class="{active: employees}"
      >
        Employee's Only
      </b-btn>
      <b-btn
          class="mx-2"
          variant="success"
          v-if="!showUserAdd"
          @click="showUserAdd = true"
      >
        <b-icon-plus/>
      </b-btn>
    </div>
    <new-user :show="showUserAdd"/>
    <div class="d-flex flex-column align-items-center">
      <User v-for="(user, ind) in admins" :key="user.email + ind" :user="user" v-on:userUpdate="getUsers"/>
      <User v-for="(user, ind) in nonAdmins" :key="user.email + 'regular' + ind" :user="user" v-on:userUpdate="getUsers"/>
    </div>
  </b-container>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import Admin from "./admin";

import {
  collection,
  getFirestore,
  query,
  limit,
  onSnapshot,
} from "firebase/firestore";
import User from "@/components/admin/User";
import EmployeeRequest from "../../components/admin/EmployeeRequest";
import NewUser from "@/components/admin/NewUser";

const usersRef = collection(getFirestore(), "users");

export default {
  name: "Users",
  components: {NewUser, EmployeeRequest, User, Admin, PageTitle},
  data() {
    return {
      usersUnfiltered: [],
      employees: false,
      showUserAdd: false,
      emailSearch: "",
      fields: [
        {
          key: 'email',
          sortable: true,
          label: 'User Email'
        }
      ]
    };
  },
  computed: {
    employeeRequests() {
      return this.users.filter((user) => {
        return user.employeeRequested
      })
    },
    admins() {
      return this.users.filter((user) => {
        return user.isAdmin;
      }).filter(user => user.email.includes(this.emailSearch));
    },
    nonAdmins() {
      return this.users.filter((user) => {
        return !user.isAdmin;
      }).filter(user => user.email.includes(this.emailSearch));
    },
    users() {
      if (this.employees) {
        return this.usersUnfiltered.filter(u => u.employee);
      }
      return this.usersUnfiltered;
    }
  },
  methods: {
    getUsers() {
      const q = query(usersRef);
      onSnapshot(q, (qSnap) => {
        this.usersUnfiltered = [];
        qSnap.forEach((user) => {
          this.usersUnfiltered.push({...user.data(), id: user.id});
        });
      });

    }
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped>
.active {
  background-color: green !important;
  color: white !important;
}

</style>
