<template>
  <b-row class="user-container justify-content-between" :class="{ admin: isAdmin, nonAdmin: !isAdmin }">
    <b-col lg class="d-flex align-items-center">
      {{ user.email }}
    </b-col>
    <b-col lg class="d-flex align-items-center justify-content-end">
      <b-form-select
          class="dropdown"
          :options="options"
          v-model="restaurant"
      />
      <b-btn
          v-if="(user.restaurant || restaurant) && restaurant !== user.restaurant"
          variant="success"
          class="ml-2"
          @click="updateRestaurant"
          :disabled="savingRestaurant"
      >
        <Loading v-if="savingRestaurant"></Loading>
        <span v-else>
          Save
        </span>
      </b-btn>
    </b-col>
    <b-col lg class="d-flex align-items-center justify-content-end mt-2">
      <b-spinner v-if="loading" class="ml-auto"></b-spinner>

      <b-dropdown
          text="Create Fundraiser"
          class="mr-2 fundraiser-dropdown"
      >
        <b-dropdown-item
          v-for="fund in fundraisers"
          :to="`/fundraiser/${fund.id}?user=${user.id}&agreed=${true}`"
        >{{fund.name}}</b-dropdown-item>
      </b-dropdown>

      <b-btn size="sm" variant="danger" v-if="isAdmin" @click="removeAdmin">
        Remove Admin
      </b-btn>
      <b-btn variant="success" size="sm" v-else @click="makeAdmin">
        Add Admin
      </b-btn>
      <b-btn class="ml-2" size="sm" variant="danger" v-if="isEmployee" @click="removeEmployee">
        Remove Employee
      </b-btn>
      <b-btn class="ml-2" variant="success" size="sm" v-else @click="makeEmployee">
        Add Employee
      </b-btn>
      <b-btn class="ml-2" size="sm" variant="danger" v-if="confirmDelete" @click="removeUser">
        Delete
      </b-btn>
      <b-btn class="ml-2 h-100" variant="info" @click="setDeleteConfirm" v-else>
        <b-icon-trash-fill/>
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import {httpsCallable, getFunctions} from "firebase/functions";
import {updateDoc, doc, getFirestore} from 'firebase/firestore';
import {mapState} from 'vuex';
import Loading from "@/components/shared/Loading";

export default {
  component: Loading,
  name: "User",
  props: ["user"],
  data() {
    return {
      loading: false,
      restaurant: null,
      savingRestaurant: false,
      confirmDelete: false
    };
  },
  mounted() {
    this.restaurant = this.user.restaurant || null;
  },
  computed: {
    ...mapState('restaurants', ['restaurants']),
    ...mapState('fundraisers', ['fundraisers']),
    options() {
      let restaurants = this.restaurants.map(r => {
        return {
          value: r.id,
          text: `${r.city}, ${r.state}`
        }
      })
      restaurants.unshift({
        value: null,
        text: "Not a restaurant operator"
      })
      return restaurants;
    },
    isAdmin() {
      return this.user.isAdmin;
    },
    isEmployee() {
      return this.user.employee;
    }
  },
  methods: {
    makeAdmin() {
      this.loading = true;
      this.makeAdmin = "";
      httpsCallable(
          getFunctions(),
          "addAdminRole"
      )({email: this.user.email, id: this.user.id}).then((result) => {
        this.loading = false;
        this.$emit("userUpdate");
      });
    },

    removeUser() {
      httpsCallable(
          getFunctions(),
          "removeUser"
      )({
        email: this.user.email
      }).then(() => {
        this.$notify({
          group: 'main-notifications',
          title: "User Removed",
          type: 'success'
        })
        this.$emit("userUpdate")
      })
    },

    setDeleteConfirm() {
      this.confirmDelete = true;
      setTimeout(() => {
        this.confirmDelete = false;
      }, 3000)
    },

    makeEmployee() {
      this.loading = true;
      httpsCallable(
          getFunctions(),
          "setEmployeeRole"
      )({email: this.user.email, id: this.user.id}).then((result) => {
        this.loading = false;
        this.$emit("userUpdate");
      })
    },

    removeAdmin() {
      this.loading = true;
      this.removeAdmin = "";
      httpsCallable(
          getFunctions(),
          "removeAdminRole"
      )({email: this.user.email, id: this.user.id}).then((result) => {
        this.loading = false;
        this.$emit("userUpdate");
      });
    },

    removeEmployee() {
      this.loading = true;
      httpsCallable(
          getFunctions(),
          "removeEmployeeRole"
      )({email: this.user.email, id: this.user.id}).then((result) => {
        this.loading = false;
        this.$emit("userUpdate");
      })
    },

    updateRestaurant() {
      this.savingRestaurant = true;
      updateDoc(doc(getFirestore(), `users/${this.user.id}`), {
        restaurant: this.restaurant
      })
          .then(() => {
            this.savingRestaurant = false;
          })
    }
  },
};
</script>

<style scoped>
.admin {
  background-color: rgba(182, 63, 63, 0.103);
}

.nonAdmin {
  background-color: rgba(101, 94, 94, 0.1);
}

.user-container {
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.dropdown {
  width: 200px;
  height: 30px;
  font-size: 12px;
}

/deep/ .fundraiser-dropdown button{
  padding-top: 3px;
  padding-bottom: 3px;
}

/deep/ .dropdown-toggle::after {
  margin-left: 10px;
  margin-right: 0px;
  margin-top: 2px;
}
</style>
