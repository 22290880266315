<template>
  <div>
    <b-form-input v-model="email" placeholder="User email to make admin"/>
    <b-btn @click="makeAdmin">Make Admin</b-btn>
  </div>
</template>

<script>
  import {httpsCallable, getFunctions} from 'firebase/functions';

  export default {
    name: "admin",
    data() {
      return {
        email: ''
      }
    },
    methods: {
      makeAdmin() {
        httpsCallable(getFunctions(), 'addAdminRole')({email: this.email})
          .then(result => {
            console.log(result);
          })
      }
    }
  }
</script>

<style scoped>

</style>
